/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import { AppBar, Button, IconButton, Slide, Stack, Toolbar, } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Logo from '../containers/images/startMyExam.png';
import * as Actions from '../store/actions';
import { useStyle } from '../styles/style';
import Search from './BaseComponents/Search';
import Courses from './LandingPage/Courses';
import ProfileIcon from './ProfileIcon';
const NewHeader = () => {
    const classes = useStyle();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [tenantLogo, setTenantLogo] = useState();
    const [isCoursesVisible, setIsCoursesVisible] = useState(null);
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const { tenantMeta } = useSelector((state) => state.exam);
    useEffect(() => {
        var _a, _b, _c, _d;
        if (tenantMeta === null || tenantMeta === void 0 ? void 0 : tenantMeta.length) {
            setTenantLogo((_d = (_c = (_b = (_a = tenantMeta.filter((meta) => meta.metaName === 'TENANT_LOGO')) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.TenantMetaInfos) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.tenantMeta);
        }
    }, [tenantMeta]);
    const handleLoginClick = () => {
        dispatch(Actions.postPageAudit({ page: 'STUDENT PORTAL:LoginMasterOpen' }));
        dispatch(Actions.loginMasterOpen());
    };
    const handleSignUpClick = () => {
        dispatch(Actions.postPageAudit({ page: 'STUDENT PORTAL:SignUpMasterOpen' }));
        dispatch(Actions.signUpMasterOpen());
    };
    return (
    // <div className={classes.newHeader}>
    //   <Stack direction="row" gap="1rem">
    //     <img
    //       src={Logo}
    //       alt="logo"
    //       className={classes.logo}
    //       onClick={() => {
    //         navigate('/', { replace: true });
    //       }}
    //     />
    //     <Button
    //       variant="outlined"
    //       style={{ fontWeight: '500' }}
    //       onClick={(ref) => setIsCoursesVisible(ref.currentTarget)}
    //       endIcon={<KeyboardArrowDownIcon />}
    //     >
    //       Courses
    //     </Button>
    //     {isCoursesVisible && (
    //       <div>
    //         <Courses
    //           isVisible={isCoursesVisible}
    //           setIsCoursesVisible={setIsCoursesVisible}
    //         />
    //       </div>
    //     )}
    //   </Stack>
    //   {!!tenantMeta?.length && (
    //     <img
    //       alt="logo"
    //       src={tenantLogo}
    //       style={{ height: '2.75rem', marginRight: '12rem' }}
    //     />
    //   )}
    //   <Search />
    //   <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
    //     <Button
    //       size="small"
    //       onClick={() => {
    //         navigate('/', { replace: true });
    //       }}
    //     >
    //       Home
    //     </Button>
    //     <Button
    //       style={{ color: 'black' }}
    //       size="small"
    //       onClick={() => {
    //         window.location.replace(
    //           process.env.REACT_APP_TEACHER_PORTAL_URL ?? '',
    //         );
    //       }}
    //     >
    //       For Teacher
    //     </Button>
    //     <Button
    //       style={{ color: 'black' }}
    //       size="small"
    //       onClick={() => {
    //         window.location.href =
    //           'https://zonatechdigital.com/products/education-management-software.html';
    //       }}
    //     >
    //       For Corporate
    //     </Button>
    //     <Button
    //       style={{ color: 'black' }}
    //       size="small"
    //       onClick={() => {
    //         window.location.href =
    //           'https://zonatechdigital.com/products/education-management-software.html';
    //       }}
    //     >
    //       For Institute
    //     </Button>
    //     {!user.loggedIn ? (
    //       <>
    //         <Button
    //           style={{ color: 'black' }}
    //           onClick={handleLoginClick}
    //           size="small"
    //         >
    //           Log In
    //         </Button>
    //         <Button
    //           variant="contained"
    //           size="small"
    //           onClick={handleSignUpClick}
    //           style={{ backgroundColor: '#4154F1' }}
    //         >
    //           Sign Up
    //         </Button>
    //       </>
    //     ) : (
    //       <>
    //         <Button
    //           onClick={() => {
    //             navigate('/my-learning');
    //           }}
    //         >
    //           My Learning
    //         </Button>
    //         <ProfileIcon />
    //       </>
    //     )}
    //   </div>
    // </div>
    React.createElement(React.Fragment, null,
        React.createElement(AppBar, { color: "inherit", position: "static", style: { overflow: 'hidden' } },
            React.createElement(Toolbar, null,
                React.createElement(Stack, { gap: "1rem", direction: "row", alignItems: "center", justifyContent: "space-between", style: { width: '-webkit-fill-available' } },
                    React.createElement("img", { src: Logo, alt: "logo", className: classes.logo, onClick: () => {
                            navigate('/', { replace: true });
                        } }),
                    React.createElement(Button, { variant: "outlined", style: { fontWeight: '500' }, onClick: (ref) => setIsCoursesVisible(ref.currentTarget), endIcon: React.createElement(KeyboardArrowDownIcon, null), className: classes.headerCourseButton }, "Courses"),
                    isCoursesVisible && (React.createElement("div", null,
                        React.createElement(Courses, { isVisible: isCoursesVisible, setIsCoursesVisible: setIsCoursesVisible }))),
                    React.createElement("div", { className: classes.searchVisible },
                        React.createElement(Search, null)),
                    React.createElement(Stack, { gap: "1rem", direction: "row", alignItems: "center" },
                        React.createElement(Stack, { gap: "1rem", direction: "row", className: classes.headerButtonCompressed },
                            React.createElement(Button, { size: "small", onClick: () => {
                                    navigate('/', { replace: true });
                                } }, "Home"),
                            React.createElement(Button, { size: "small", onClick: handleLoginClick }, "For Student"),
                            React.createElement(Button, { size: "small", onClick: () => {
                                    var _a;
                                    window.location.replace((_a = process.env.REACT_APP_TEACHER_PORTAL_URL) !== null && _a !== void 0 ? _a : '');
                                } }, "For Teacher"),
                            React.createElement(Button, { size: "small", onClick: () => {
                                    window.location.href =
                                        'https://zonatechdigital.com/products/education-management-software.html';
                                } }, "For Corporate"),
                            React.createElement(Button, { size: "small", onClick: () => {
                                    window.location.href =
                                        'https://zonatechdigital.com/products/education-management-software.html';
                                } }, "For Institute")),
                        !user.loggedIn ? (React.createElement(React.Fragment, null,
                            React.createElement(IconButton, { className: classes.searchCompressed, onClick: () => setIsSearchVisible((prevValue) => !prevValue) },
                                React.createElement(SearchIcon, null)),
                            React.createElement(Button, { variant: "outlined", style: { color: 'black' }, onClick: handleLoginClick, size: "small" }, "Log In"),
                            React.createElement(Button, { variant: "contained", size: "small", onClick: handleSignUpClick, style: { backgroundColor: '#4154F1' } }, "Sign Up"))) : (React.createElement(React.Fragment, null,
                            React.createElement(Button, { onClick: () => {
                                    navigate('/my-learning');
                                } }, "My Learning"),
                            React.createElement(ProfileIcon, null))))),
                ' '),
            React.createElement(Slide, { direction: "down", in: isSearchVisible, mountOnEnter: true, unmountOnExit: true },
                React.createElement(Stack, { justifyContent: "center", alignItems: "center", padding: "0.5rem 0" },
                    React.createElement(Search, null)))),
        ' '));
};
export default NewHeader;
