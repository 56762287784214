import React from 'react';
import { useStyle } from '../../styles/style';
import NestedMenu from '../BaseComponents/NestedMenu';
import { useNavigate } from 'react-router-dom';
const Courses = ({ isVisible, setIsCoursesVisible }) => {
    const navigate = useNavigate();
    const Course = [
        {
            key: '779d3838-df8e-4dc9-b456-4d1d9d239ec4',
            caption: 'Computer Engineering',
            subMenu: [
                {
                    key: '14626a4e-4911-4986-b0a3-bd390a42b0cf',
                    caption: 'exam a',
                    onClick: () => navigate('/14626a4e-4911-4986-b0a3-bd390a42b0cf', {
                        replace: true,
                    }),
                },
                {
                    key: '22fd1982-f856-4e8c-b6b6-0fa278bbb4dc',
                    caption: 'Test Exam',
                    subMenu: [
                        {
                            key: '14626a4e-4911-4986-b0a3-bd390a42b0cf',
                            caption: 'exam a',
                            onClick: () => navigate('/14626a4e-4911-4986-b0a3-bd390a42b0cf', {
                                replace: true,
                            }),
                        },
                        {
                            key: '22fd1982-f856-4e8c-b6b6-0fa278bbb4dc',
                            caption: 'Test Exam',
                            onClick: () => navigate('/22fd1982-f856-4e8c-b6b6-0fa278bbb4dc', {
                                replace: true,
                            }),
                        },
                    ],
                },
            ],
        },
    ];
    const classes = useStyle();
    return (React.createElement(NestedMenu, { open: isVisible, anchorEl: isVisible, configuration: Course, onClose: () => setIsCoursesVisible(null) }));
};
export default Courses;
