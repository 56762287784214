import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Actions from '../../store/actions';
import NewLandingPage from '../LandingPage/NewLandingPage';
const Registration = ({ tenantCode }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(Actions.signUpModalOpen());
        dispatch(Actions.getTenantDetailByCode({
            tenantCode,
        }));
        dispatch(Actions.postPageAudit({
            page: `STUDENT PORTAL:SignUpUsingTenantCode-${tenantCode}`,
        }));
        dispatch(Actions.getAllExams());
    }, []);
    return React.createElement(NewLandingPage, null);
};
export default Registration;
