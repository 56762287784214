import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useStyle } from '../styles/style';
import Modal from './Modal';
import NewFooter from './NewFooter';
import NewHeader from './NewHeader';
import { useSelector } from 'react-redux';
const MainComponent = () => {
    const classes = useStyle();
    const location = useLocation();
    const { user } = useSelector((state) => state.auth);
    return (React.createElement("div", { className: classes.main },
        React.createElement(NewHeader, null),
        React.createElement(Outlet, null),
        React.createElement(NewFooter, null),
        React.createElement(Modal, null)));
};
export default MainComponent;
