import { makeStyles } from '@material-ui/core';
import { PRIMARY_COLOR } from './colors';
export const useStyle = makeStyles((theme) => ({
    logo: {
        width: theme.typography.pxToRem(200),
        cursor: 'pointer',
        '@media (max-width: 480px)': {
            width: theme.typography.pxToRem(100),
            height: theme.typography.pxToRem(32),
        },
    },
    main: {
        display: 'flex',
        width: '-webkit-fill-available',
        flexDirection: 'column',
        flexWrap: 'wrap',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        background: 'white',
        height: theme.typography.pxToRem(48),
        alignItems: 'center',
        padding: `0 ${theme.typography.pxToRem(16)}`,
        boxShadow: '0 4px 13px -3px rgba(0, 0, 0, 0.10196)',
        width: '98%',
        position: 'fixed',
        zIndex: 999,
        '@media (max-width: 480px)': {
            width: '94vw',
        },
    },
    footer: {
        background: PRIMARY_COLOR.BACKGROUND,
        padding: `${theme.typography.pxToRem(32)} ${theme.typography.pxToRem(48)} ${theme.typography.pxToRem(24)}`,
    },
    loader: {
        height: theme.typography.pxToRem(16),
        mixBlendMode: 'multiply',
    },
    loaderDiv: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        width: theme.typography.pxToRem(342),
    },
    curtain: {
        background: PRIMARY_COLOR.GREY0,
        position: 'absolute',
        opacity: '50%',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999,
    },
    error: {
        color: theme.palette.error.main,
    },
    navLink: {
        textDecoration: 'none',
        color: PRIMARY_COLOR.GREY150,
    },
    formLabel: {
        '&.Mui-focused': {
            color: 'rgba(0, 0, 0, 0.6) !important',
        },
    },
    blue: {
        color: '#00a2ed',
    },
    typo: {
        color: 'white',
        fontSize: theme.typography.pxToRem(12),
        fontFamily: 'cursive',
        cursor: 'pointer',
    },
    mainBody: {
        display: 'flex',
        width: '-webkit-fill-available',
        flexWrap: 'wrap',
        flexDirection: 'column',
        minHeight: '90vh',
        '@media (max-width: 480px)': {},
    },
    scrollDefault: {
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: '5px',
        },
        '&::-webkit-scrollbar-track': {
            background: 'white' /* color of the tracking area */,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#585858' /* color of the scroll thumb */,
            borderRadius: '20px' /* roundness of the scroll thumb */,
        },
    },
    imageSlider: {
        width: '100%',
        height: theme.typography.pxToRem(430),
        borderRadius: theme.typography.pxToRem(10),
        backgroundSize: 'cover !important',
        backgroundPosition: 'center !important',
        overflow: 'hidden !important',
        '@media (max-width: 480px)': {},
    },
    sliderRightArrow: {
        position: 'absolute',
        top: '50%',
        transform: 'translate(0, -50%)',
        right: '32px',
        zIndex: 1,
        backgroundColor: PRIMARY_COLOR.GREY125,
        borderRadius: '50%',
        display: 'block',
        '&>hover': {
            display: 'block',
        },
    },
    sliderLeftArrow: {
        position: 'absolute',
        top: '50%',
        transform: 'translate(0, -50%)',
        left: '32px',
        zIndex: 1,
        backgroundColor: PRIMARY_COLOR.GREY125,
        borderRadius: '50%',
        display: 'block',
        '&>hover': {
            display: 'block',
        },
    },
    sliderStyles: {
        position: 'relative',
        height: '100%',
    },
    dotsContainerStyles: {
        display: 'flex',
        width: '-webkit-fill-available',
        justifyContent: 'center',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translate(-50%, 0)',
        borderRadius: '6px',
    },
    dotStyle: { margin: '0 3px', height: '24px' },
    landingMain: {
        // marginTop: theme.typography.pxToRem(128),
        '@media (max-width: 480px)': {
            flexDirection: 'column',
        },
    },
    landingExamDetails: {
        padding: theme.typography.pxToRem(16),
        borderRadius: theme.typography.pxToRem(10),
        // width: theme.typography.pxToRem(748),
        '@media (max-width: 480px)': {
            width: '86vw',
        },
    },
    landingExamDetail: {
        margin: '1rem 1rem 0 0',
        // width: theme.typography.pxToRem(720),
        padding: theme.typography.pxToRem(16),
        '@media (max-width: 480px)': {
            width: 'auto',
        },
    },
    landingExamDetailMain: {
        display: 'flex',
        width: '-webkit-fill-available',
        flexDirection: 'column',
        minHeight: theme.typography.pxToRem(320),
        justifyContent: 'center',
    },
    center: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerUnderline: {
        borderBottom: `1px solid ${PRIMARY_COLOR.GREY80}`,
    },
    examTitle: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    headerButtonDiv: {
        display: 'flex',
        width: '-webkit-fill-available',
        flexDirection: 'row',
        minWidth: theme.typography.pxToRem(320),
        justifyContent: 'space-evenly',
        '@media (max-width: 480px)': {
            display: 'none',
        },
    },
    examLandingMain: {
        minHeight: '90vh',
    },
    examLandingTopContainer: {
        backgroundColor: PRIMARY_COLOR.BACKGROUND,
        padding: `${theme.typography.pxToRem(50)} 0`,
        color: theme.palette.common.white,
    },
    examLandingTopInner: {
        margin: `0 ${theme.typography.pxToRem(108)}`,
        maxWidth: theme.typography.pxToRem(700),
        '@media (max-width: 480px)': {
            margin: `0 ${theme.typography.pxToRem(40)}`,
        },
    },
    examLandingTitle: {
        marginBottom: `${theme.typography.pxToRem(13)} !important`,
    },
    examLandingSidePanel: {
        marginLeft: theme.typography.pxToRem(930),
        position: 'absolute',
        top: theme.typography.pxToRem(98),
        backgroundColor: theme.palette.common.white,
        width: theme.typography.pxToRem(320),
        boxShadow: '0 2px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08)',
        '@media (max-width: 480px)': {
            display: 'none',
        },
    },
    examLandingImage: {
        width: theme.typography.pxToRem(320),
        height: theme.typography.pxToRem(176),
    },
    examLandingSidePanelMain: {
        padding: theme.typography.pxToRem(16),
        '& > *': {
            marginBottom: `${theme.typography.pxToRem(12)} !important`,
        },
    },
    examLandingPrice: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(32),
    },
    buyButton: {
        display: 'none !important',
        '@media (max-width: 480px)': {
            display: 'block !important',
            marginBottom: `${theme.typography.pxToRem(32)} !important`,
        },
    },
    examLandingBody: {
        padding: `${theme.typography.pxToRem(32)} 0 0 ${theme.typography.pxToRem(108)}`,
        maxWidth: theme.typography.pxToRem(700),
        '& > *': {
            marginBottom: theme.typography.pxToRem(24),
        },
        '@media (max-width: 480px)': {
            padding: `${theme.typography.pxToRem(32)} ${theme.typography.pxToRem(32)} 0 ${theme.typography.pxToRem(32)}`,
        },
    },
    examLandingLoaderDiv: {
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
    examLandingBodyTnc: {
        borderRadius: theme.typography.pxToRem(3),
        border: `${theme.typography.pxToRem(1)} ${PRIMARY_COLOR.GREY40} solid`,
        padding: theme.typography.pxToRem(16),
        '& > *': {
            marginBottom: `${theme.typography.pxToRem(12)} !important`,
        },
    },
    examLandingTnc: {
        marginLeft: theme.typography.pxToRem(8),
        fontSize: theme.typography.pxToRem(14),
        color: PRIMARY_COLOR.GREY125,
    },
    trendingExamBox: {
        width: theme.typography.pxToRem(251),
        border: `${theme.typography.pxToRem(1)} ${PRIMARY_COLOR.GREY40} solid`,
        borderRadius: theme.typography.pxToRem(8),
        boxShadow: '0 2px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08)',
        '@media (max-width: 480px)': {
            width: theme.typography.pxToRem(200),
        },
    },
    trendingExamImage: {
        width: theme.typography.pxToRem(251),
        borderRadius: '8px 8px 0 0',
        '@media (max-width: 480px)': {
            width: theme.typography.pxToRem(200),
        },
    },
    trendingExamBody: {
        padding: theme.typography.pxToRem(8),
        '& > *': {
            marginBottom: theme.typography.pxToRem(8),
        },
    },
    trendingExamsMain: {
        display: 'flex',
        width: '-webkit-fill-available',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginLeft: theme.typography.pxToRem(12),
        alignContent: 'flex-start',
        '& > *': {
            marginBottom: theme.typography.pxToRem(12),
        },
        '&>*:nth-child(odd)': {
            marginRight: theme.typography.pxToRem(12),
        },
        '@media (max-width: 480px)': {
            flexWrap: 'noWrap',
            overflowY: 'hidden',
            width: '86vw',
            '& > *': {
                marginRight: theme.typography.pxToRem(12),
            },
        },
    },
    pagination: {
        display: 'flex',
        width: '-webkit-fill-available',
        marginTop: theme.typography.pxToRem(48),
        justifyContent: 'flex-end',
    },
    paginationPageItem: {
        marginRight: theme.typography.pxToRem(12),
        padding: theme.typography.pxToRem(6),
        cursor: 'pointer',
    },
    paginationPageItemSelected: {
        backgroundColor: PRIMARY_COLOR.PRIMARY,
        color: 'white',
        borderRadius: theme.typography.pxToRem(5),
    },
    landingExamList: {
        display: 'flex',
        width: '-webkit-fill-available',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: '3rem',
        justifyContent: 'space-between',
    },
    examLandingSets: {
        padding: '1rem',
        backgroundColor: PRIMARY_COLOR.GREY20,
        border: `1px ${PRIMARY_COLOR.GREY40} solid`,
    },
    examLandingSetBox: {
        borderRadius: theme.typography.pxToRem(3),
        border: `${theme.typography.pxToRem(1)} ${PRIMARY_COLOR.GREY40} solid`,
    },
    divider: {
        border: `1px ${PRIMARY_COLOR.GREY150} solid`,
        margin: `${theme.typography.pxToRem(16)} 0`,
    },
    footerContent: {
        '& > *': {
            margin: `${theme.typography.pxToRem(6)} !important`,
        },
    },
    footerLogo: {
        display: 'flex',
        width: '-webkit-fill-available',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        '@media (max-width: 480px)': { flexDirection: 'column' },
    },
    myLearningTopContainer: {
        backgroundColor: PRIMARY_COLOR.BACKGROUND,
        padding: `${theme.typography.pxToRem(50)} 0 ${theme.typography.pxToRem(16)} 0`,
        color: theme.palette.common.white,
    },
    myLearningTopInnerDiv: {
        margin: `0 ${theme.typography.pxToRem(160)}`,
        '@media (max-width: 480px)': {
            margin: `0 ${theme.typography.pxToRem(32)}`,
        },
    },
    myLearningtabIndicator: {
        backgroundColor: 'white !important',
    },
    fullScreen: {
        background: 'white',
        height: '92vh',
        padding: theme.typography.pxToRem(32),
        '-moz-user-select': 'none' /* firefox */,
        '-webkit-user-select': 'none' /* Safari */,
        '-ms-user-select': 'none' /* IE*/,
        'user-select': 'none' /* Standard syntax */,
    },
    fullScreenGrey: {
        background: PRIMARY_COLOR.GREY10,
        height: '92vh',
        padding: theme.typography.pxToRem(32),
        '-moz-user-select': 'none' /* firefox */,
        '-webkit-user-select': 'none' /* Safari */,
        '-ms-user-select': 'none' /* IE*/,
        'user-select': 'none' /* Standard syntax */,
    },
    tncMain: {
        background: PRIMARY_COLOR.GREY0,
        padding: theme.typography.pxToRem(32),
        marginTrim: 'block-end',
        '& > *': {
            marginBottom: `${theme.typography.pxToRem(24)} !important`,
        },
    },
    tncTitle: {
        fontSize: `${theme.typography.pxToRem(40)} !important`,
        fontWeight: 700,
    },
    checkboxDiv: {
        display: 'flex',
        width: '-webkit-fill-available',
        alignItems: 'center',
    },
    examQuestionMain: {
        width: '74vw',
        '& > *': {
            marginBottom: '0.5rem',
        },
    },
    questionTopBar: {
        display: 'flex',
        width: '-webkit-fill-available',
        padding: '0.5rem 3rem 0.5rem 1rem',
        background: 'white',
        justifyContent: 'space-between',
        flexDirection: 'row',
        fontWeight: 600,
        fontSize: '1.2rem',
        alignItems: 'center',
        color: PRIMARY_COLOR.GREY150,
    },
    questionBody: {},
    questionBodyHeader: {
        padding: theme.typography.pxToRem(16),
        background: 'white',
        fontWeight: 400,
        fontSize: '1rem',
        borderBottom: `1px solid ${PRIMARY_COLOR.GREY80} `,
        display: 'flex',
        width: '-webkit-fill-available',
        justifyContent: 'space-between',
    },
    questionBodyMain: {
        padding: theme.typography.pxToRem(16),
        background: 'white',
        minHeight: theme.typography.pxToRem(320),
    },
    questionChoices: {
        marginTop: theme.typography.pxToRem(8),
    },
    questionChoice: {
        display: 'flex',
        width: '-webkit-fill-available',
        alignItems: 'center',
        borderRadius: theme.typography.pxToRem(5),
        border: '1px solid transparent',
        padding: `0 ${theme.typography.pxToRem(12)}`,
        '&:hover': {
            border: `1px solid ${PRIMARY_COLOR.GREY60}`,
        },
    },
    questionLearningChoice: {
        display: 'flex',
        width: '-webkit-fill-available',
        alignItems: 'center',
        borderRadius: theme.typography.pxToRem(5),
        border: '1px solid transparent',
        padding: `0 ${theme.typography.pxToRem(12)}`,
    },
    questionButtons: {
        padding: theme.typography.pxToRem(16),
        background: 'white',
        borderTop: `1px solid ${PRIMARY_COLOR.GREY80} `,
        display: 'flex',
        width: '-webkit-fill-available',
        justifyContent: 'space-between',
    },
    questionParent: {
        display: 'flex',
        width: '96vw',
        justifyContent: 'space-between',
    },
    questionSidePanel: {
        width: '22vw',
        background: 'white',
        marginLeft: theme.typography.pxToRem(16),
        minHeight: theme.typography.pxToRem(560),
        maxHeight: theme.typography.pxToRem(560),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    questionStack: {
        display: 'flex',
        width: '-webkit-fill-available',
        flexWrap: 'wrap',
        alignItems: 'center',
        maxHeight: theme.typography.pxToRem(336),
    },
    questionNum: {
        width: theme.typography.pxToRem(32),
        border: '1px solid #F0F0F0',
        height: theme.typography.pxToRem(32),
        margin: theme.typography.pxToRem(6),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme.typography.pxToRem(6),
        color: 'white',
        fontWeight: 600,
        cursor: 'pointer',
    },
    questionNumMarked: {
        backgroundColor: '#42b06e',
    },
    questionNumNotMarked: {
        backgroundColor: '#EC4F4F',
    },
    questionNumReview: {
        backgroundColor: '#FFB800',
    },
    choiceChar: {
        border: `1px solid ${PRIMARY_COLOR.GREY50}`,
        padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(12)}`,
        borderRadius: '50%',
        marginRight: theme.typography.pxToRem(32),
    },
    questionChoiceSelected: {
        color: 'white',
        backgroundColor: PRIMARY_COLOR.PRIMARY,
    },
    marksDiv: {
        display: 'flex',
        width: '-webkit-fill-available',
        '& > *': {
            marginRight: theme.typography.pxToRem(12),
        },
    },
    textGrey: {
        color: PRIMARY_COLOR.GREY80,
    },
    textGreen: {
        color: '#42b06e',
    },
    textRed: {
        color: '#EC4F4F',
    },
    submitButton: {
        padding: theme.typography.pxToRem(16),
    },
    timer: {
        fontSize: theme.typography.pxToRem(28),
        fontWeight: 700,
        fontFamily: 'system-ui',
        '& > *': {
            marginRight: theme.typography.pxToRem(8),
        },
    },
    circle: {
        width: theme.typography.pxToRem(12),
        height: theme.typography.pxToRem(12),
        borderRadius: '50%',
        marginRight: theme.typography.pxToRem(12),
    },
    questionMarkingScheme: {
        display: 'flex',
        width: '-webkit-fill-available',
        flexWrap: 'wrap',
        alignItems: 'center',
        padding: theme.typography.pxToRem(16),
        borderTop: `1px solid ${PRIMARY_COLOR.GREY100}`,
        justifyContent: 'space-between',
        '& > *': {
            marginBottom: theme.typography.pxToRem(12),
        },
    },
    rowAlign: {
        display: 'flex',
        alignItems: 'center',
    },
    upcomingExam: {
        display: 'flex',
        width: '-webkit-fill-available',
        flexWrap: 'wrap',
        '& > *': {
            marginBottom: theme.typography.pxToRem(8),
        },
    },
    centerComp: {
        display: 'flex',
        width: '-webkit-fill-available',
        alignItems: 'center',
        justifyContent: 'center',
    },
    setButtonMain: {
        border: `1px solid ${PRIMARY_COLOR.GREY80}`,
        borderRadius: theme.typography.pxToRem(4),
        margin: `0 ${theme.typography.pxToRem(8)}`,
        padding: theme.typography.pxToRem(30),
        flexWrap: 'wrap',
        flex: 1,
        minWidth: theme.typography.pxToRem(400),
        maxWidth: theme.typography.pxToRem(400),
        alignContent: 'space-between',
    },
    setButton: {
        '& > *': {
            marginRight: theme.typography.pxToRem(16),
            marginBottom: theme.typography.pxToRem(8),
        },
    },
    displayFlex: {
        display: 'flex',
        width: '-webkit-fill-available',
    },
    loginModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: theme.typography.pxToRem(400),
        background: 'white',
        borderRadius: '1rem',
        padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(32)}`,
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
        '@media (max-width: 480px)': {
            width: theme.typography.pxToRem(300),
        },
    },
    loginHeader: {
        fontSize: theme.typography.pxToRem(28),
        fontWeight: 600,
        marginBottom: theme.typography.pxToRem(16),
    },
    loginBody: {
        '& > *': {
            marginBottom: `${theme.typography.pxToRem(10)} !important`,
        },
    },
    closeIconBody: {
        display: 'flex',
        width: '-webkit-fill-available',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    teacherLoginIcon: {
        height: theme.typography.pxToRem(110),
        padding: theme.typography.pxToRem(8),
        borderRadius: '50%',
    },
    loginIcon: {
        display: 'flex',
        width: '-webkit-fill-available',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#D0EFFC',
            borderRadius: theme.typography.pxToRem(4),
        },
    },
    loginIconTab: {
        display: 'flex',
        width: '-webkit-fill-available',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        margin: theme.typography.pxToRem(12),
    },
    signUpModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: theme.typography.pxToRem(480),
        background: 'white',
        padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(32)}`,
        '@media (max-width: 480px)': {
            width: theme.typography.pxToRem(300),
        },
    },
    myAccount: {
        padding: `${theme.typography.pxToRem(48)} ${theme.typography.pxToRem(160)}`,
        marginTop: theme.typography.pxToRem(48),
        minHeight: '90vh',
        display: 'flex',
        width: '-webkit-fill-available',
    },
    myAccountSidePanel: {
        width: theme.typography.pxToRem(288),
        height: theme.typography.pxToRem(288),
        background: PRIMARY_COLOR.GREY0,
        display: 'flex',
        justifyContent: 'center',
        padding: theme.typography.pxToRem(24),
    },
    myAccountMain: {
        marginLeft: theme.typography.pxToRem(48),
        display: 'flex',
        width: '-webkit-fill-available',
        flexDirection: 'column',
        // border: `2px solid ${PRIMARY_COLOR.GREY100}`,
    },
    myAccountTitle: {
        display: 'flex',
        width: '-webkit-fill-available',
        alignItems: 'center',
        // justifyContent: 'center',
        borderBottom: `2px solid ${PRIMARY_COLOR.GREY100}`,
        height: '12%',
    },
    myAccountFields: {
        padding: `${theme.typography.pxToRem(48)} ${theme.typography.pxToRem(80)} ${theme.typography.pxToRem(80)}`,
    },
    rightBorder: {
        borderRight: `2px solid ${PRIMARY_COLOR.GREY50}`,
    },
    resultMain: {
        marginTop: theme.typography.pxToRem(32),
        background: PRIMARY_COLOR.GREY10,
        padding: theme.typography.pxToRem(32),
        minHeight: '90vh',
    },
    resultPreview: {
        padding: theme.typography.pxToRem(16),
        background: 'white',
        fontWeight: 400,
        fontSize: '1rem',
        borderBottom: `1px solid ${PRIMARY_COLOR.GREY80} `,
        marginTop: theme.typography.pxToRem(24),
    },
    resultBox: {
        display: 'flex',
        width: '-webkit-fill-available',
        '&>*': {
            margin: theme.typography.pxToRem(16),
            display: 'flex',
            flexDirection: 'column',
            padding: theme.typography.pxToRem(16),
            borderRadius: theme.typography.pxToRem(4),
            width: theme.typography.pxToRem(160),
            color: 'white',
        },
    },
    resultPadding: {
        fontSize: '1.2rem',
        padding: theme.typography.pxToRem(16),
    },
    resultLoader: {
        display: 'flex',
        width: '-webkit-fill-available',
        alignItems: 'center',
        justifyContent: 'center',
    },
    passwordList: {
        fontSize: theme.typography.pxToRem(12),
        margin: 0,
    },
    examDetails: {
        display: 'flex',
        width: '-webkit-fill-available',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: `${theme.typography.pxToRem(8)} 0`,
        '@media (max-width: 480px)': {
            display: 'grid',
            gridTemplateColumns: '50% 50%',
        },
    },
    activeUserLogo: {
        backgroundColor: PRIMARY_COLOR.PRIMARY,
    },
    pointer: {
        cursor: 'pointer',
    },
    forgotPassword: {
        cursor: 'pointer',
        color: PRIMARY_COLOR.PRIMARY,
        margin: theme.typography.pxToRem(4),
    },
    submitModal: {
        top: '30%',
        boxShadow: `0 0 0.5rem ${PRIMARY_COLOR.GREY100}`,
        border: 'none',
    },
    forgotPasswordRequest: {
        marginTop: theme.typography.pxToRem(48),
    },
    forgotPasswordRequestBox: {
        margin: '8rem 30rem',
        boxShadow: `0 0 0.5rem ${PRIMARY_COLOR.GREY100}`,
        padding: '2rem',
    },
    contactUsBanner: {
        backgroundColor: PRIMARY_COLOR.PRIMARY,
        textAlign: 'center',
    },
    contactUsHead: {
        padding: '4.7rem 0 5rem',
        maxWidth: theme.typography.pxToRem(1250),
        margin: '0 auto',
    },
    detailCard: {
        boxShadow: '0 3px 31px #0000000f',
        background: '#fff',
        padding: '33px 23px',
        position: 'relative',
        display: 'flex',
        width: '-webkit-fill-available',
        flexWrap: 'wrap',
        borderRadius: '3px',
        marginTop: theme.typography.pxToRem(72),
        '@media (max-width: 480px)': {
            maxWidth: '75%',
            marginLeft: 'auto',
        },
    },
    examLandingDetails: {
        display: 'flex',
        width: '-webkit-fill-available',
        alignItems: 'baseline',
        flexWrap: 'wrap',
        flexDirection: 'column',
        '@media (max-width: 480px)': {
            flexDirection: 'column',
        },
    },
    navBarCompressedMain: {
        display: 'none',
        '@media (max-width: 480px)': {
            display: 'inline',
            marginLeft: '1rem',
        },
    },
    search: {
        width: '29vw !important',
        [theme.breakpoints.down('sm')]: {
            width: '30% !important',
            margin: '1rem 0',
        },
    },
    searchVisible: {
        [theme.breakpoints.down('sm')]: {
            display: 'none !important',
        },
    },
    searchCompressed: {
        display: 'none !important',
        [theme.breakpoints.down('sm')]: {
            display: 'block !important',
        },
    },
    headerButtonCompressed: {
        [theme.breakpoints.down('sm')]: {
            display: 'none !important',
        },
    },
    upcomingExamTab: {
        borderColor: 'divider',
        backgroundColor: PRIMARY_COLOR.BACKGROUND,
        padding: '0 10rem',
        '@media (max-width: 480px)': {
            padding: '0 2rem',
        },
    },
    contactUsBody: {
        border: `1px solid ${PRIMARY_COLOR.GREY80}`,
        margin: theme.typography.pxToRem(48),
        padding: theme.typography.pxToRem(48),
        display: 'flex',
        width: '-webkit-fill-available',
    },
    contactUsLogo: {
        flex: '0 0 50%',
        padding: theme.typography.pxToRem(16),
        borderRight: `1px solid ${PRIMARY_COLOR.GREY80}`,
    },
    contactUsForm: {
        paddingLeft: theme.typography.pxToRem(32),
        flex: '50%',
    },
    resultAnalysis: {
        marginTop: theme.typography.pxToRem(48),
        minHeight: '90vh',
        padding: theme.typography.pxToRem(32),
    },
    resultAnalysisTM: {
        display: 'flex',
        width: '-webkit-fill-available',
        flexDirection: 'column',
        marginBottom: theme.typography.pxToRem(32),
        borderBottom: '4px solid rgba(224, 224, 224, 1)',
    },
    resultAnalysisSA: {},
    resultAnalysisTableHeader: {
        '& .MuiTableCell-head': {
            color: PRIMARY_COLOR.GREY100,
        },
    },
    resultAnalysisTableRow: {
        '& .MuiTableCell-root': {
            fontSize: theme.typography.pxToRem(16),
        },
    },
    resultAnalysisChartMain: {
        display: 'flex',
        width: '-webkit-fill-available',
        flexDirection: 'row',
        '& > *': {
            marginRight: theme.typography.pxToRem(8),
        },
    },
    resultPieChart: {
        padding: theme.typography.pxToRem(16),
        border: `1px solid ${PRIMARY_COLOR.GREY60}`,
    },
    resultPCFooter: {
        display: 'flex',
        width: '-webkit-fill-available',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    resultAnalysisHeader: {
        display: 'flex',
        width: '-webkit-fill-available',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.typography.pxToRem(12),
        padding: theme.typography.pxToRem(8),
        borderBottom: '4px solid rgba(224, 224, 224, 1)',
    },
    termNConditionBody: {
        marginTop: theme.typography.pxToRem(48),
        padding: theme.typography.pxToRem(48),
    },
    privacyPolicy: {
        marginTop: theme.typography.pxToRem(48),
        padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(120)} ${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(120)}`,
    },
    privacyPolicyTitle: {
        display: 'flex',
        width: '-webkit-fill-available',
        justifyContent: 'center',
        marginBottom: '2rem',
        color: PRIMARY_COLOR.PRIMARY,
    },
    tncHeading: { padding: '1rem 1rem 1rem 0', color: PRIMARY_COLOR.PRIMARY },
    slideOverLayText: {
        border: `2px solid rgba(240, 240, 240)`,
        borderRadius: '1rem',
        padding: theme.typography.pxToRem(12),
    },
    paymentPageMain: {
        border: `${theme.typography.pxToRem(2)} solid ${PRIMARY_COLOR.GREY10}`,
        margin: '3vh 5vw !important',
        display: 'flex',
        width: '-webkit-fill-available',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    paymentDetails: {
        backgroundColor: PRIMARY_COLOR.GREY10,
        padding: theme.typography.pxToRem(32),
        flex: '0 0 40%',
    },
    paymentIconLogo: {
        cursor: 'pointer',
        border: `${theme.typography.pxToRem(2)} solid ${PRIMARY_COLOR.GREY10}`,
        padding: theme.typography.pxToRem(16),
        boxShadow: '0 1px 2px rgba(0,0,0,0.15)',
        transition: 'box-shadow 0.3s ease-in-out',
        '* > &': {
            marginBottom: theme.typography.pxToRem(16),
        },
        '&:hover': {
            backgroundColor: PRIMARY_COLOR.GREY20,
            boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
        },
    },
    paymentPageLogos: {
        display: 'flex',
        width: '-webkit-fill-available',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: '0 1 60%',
        padding: theme.typography.pxToRem(32),
    },
    diagonalCross: {
        textDecorationLine: `line-through`,
    },
    newExamBox: {
        boxShadow: '0px 4px 4px 0px #0000001F',
        border: '1px solid #FCFBFB',
        width: '20rem',
        borderRadius: '5px',
        background: 'white',
        paddingBottom: '1rem',
        '&:hover': {
            transform: 'scale3d(1.03, 1.03, 1.08) translate3d(0.1rem, -0.25rem, 20rem) ',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 0px 40px -8px, rgba(0, 0, 0, 0.2) 0px 0px 25px -16px',
        },
    },
    newExamBody: {
        margin: '0 1rem',
    },
    newExamBodyUpper: {
        borderBottom: '1px solid #CCCCCC',
        padding: '1rem',
        height: '3rem',
    },
    newExamBoxDetailMain: {
        padding: '0 0.5rem',
        borderRight: '1px solid #CCCCCC ',
    },
    newExamBoxDetailHeader: {
        fontSize: '0.7rem !important',
        color: '#757373',
        lineHeight: '15px !important',
    },
    newExamBoxDetail: {
        lineHeight: '15px !important',
        color: '#000000',
    },
    newExamBoxPrice: {
        fontSize: '16px !important',
        lineHeight: '19.36px !important',
    },
    newExamBoxHeader: {
        padding: '1rem',
        display: 'flex',
        justifyContent: 'center',
        height: '34px',
    },
    newExamBoxLower: {
        display: 'flex',
        width: '-webkit-fill-available',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '-webkit-fill-available',
    },
    newExamBoxType: {
        background: '#15AE654F',
        borderRadius: '5px',
        color: '#10A45D',
        fontSize: '0.75rem !important',
        padding: '0.2rem 0.4rem',
    },
    newcontactUsBody: {
        display: 'flex',
        width: '-webkit-fill-available',
        flexDirection: 'row',
        marginTop: '3rem',
        padding: '3vw',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    newcontactUsLeft: {
        flex: '50%',
        display: 'flex',
        width: '-webkit-fill-available',
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        '&>*': {
            margin: '1rem',
        },
    },
    newcontactUsRight: {
        flex: '21%',
        background: '#F8F8F8',
        padding: '0.5rem',
        marginRight: '6vw',
        '&>*': {
            margin: '0.5rem !important',
        },
    },
    newContactUsTab: {
        background: '#F8F8F8',
        padding: '1.5rem 9vw 1.5rem 1.5rem',
        width: '6vw',
        height: '6vw',
    },
    newContactUsLogo: {
        color: '#425BF1',
        transform: 'scale(1.5)',
        marginBottom: '1rem',
    },
    newHeader: {
        padding: '1rem 2rem 1rem 2rem',
        // display: 'flex',
        // width: '-webkit-fill-available',
        // alignItems: 'center',
        // justifyContent: 'space-between',
    },
    newHomeMid: {
        display: 'flex',
        width: '-webkit-fill-available',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8rem 11rem 0 9rem',
    },
    newFooterMain: {
        background: '#f8f8f8',
        display: 'flex',
        width: '-webkit-fill-available',
        padding: '2rem',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    headerCourseButton: {
        [theme.breakpoints.down('sm')]: {
            display: 'none !important',
        },
    },
    featureBody: {
        display: 'flex',
        width: '-webkit-fill-available',
        flexDirection: 'row',
        marginTop: '3rem',
    },
    featureDetailMain: {
        display: 'flex',
        width: '-webkit-fill-available',
        alignItems: 'center',
    },
    coursesMenu: {
        minWidth: '14rem !important',
    },
    sliderText: {
        lineHeight: '2.5rem',
        fontSize: '12px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '8px',
        },
    },
}));
