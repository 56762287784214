import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useStyle } from '../../styles/style';
import ExamBoxImage from './ExamBoxImage';
import { loginModalOpen } from '../../store/actions';
const NewExamBox = (props) => {
    const { exam } = props;
    const classes = useStyle();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    return (React.createElement("div", { className: classes.newExamBox },
        React.createElement("div", { className: classes.newExamBoxHeader },
            React.createElement(Typography, { color: "#000000", fontWeight: "600", fontSize: "16px", lineHeight: "17.52px", style: {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    WebkitLineClamp: '2',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                } }, exam.examName)),
        (exam === null || exam === void 0 ? void 0 : exam.image) ? (React.createElement("img", { src: exam.image, alt: exam.name, style: { width: '-webkit-fill-available' } })) : (React.createElement(ExamBoxImage, null)),
        React.createElement("div", { className: classes.newExamBody },
            React.createElement("div", { className: classes.newExamBoxLower },
                React.createElement("div", { style: { padding: '0.5rem 0' } },
                    React.createElement(Typography, { className: classes.newExamBoxDetail, fontWeight: "600", variant: "caption" }, exam.teacherName),
                    React.createElement("div", { className: classes.rowAlign, style: { marginTop: '0.5rem' } },
                        React.createElement(Typography, { className: classes.newExamBoxDetailHeader, fontWeight: "300" }, "No. of Questions:\u00A0"),
                        React.createElement(Typography, { className: classes.newExamBoxDetail, fontWeight: "500" }, exam.noOfQuestionsPerSet * exam.noOfSets))),
                React.createElement(Stack, { justifyContent: "space-between", alignItems: "center", direction: "row", padding: "0.5rem 0" },
                    React.createElement("div", { className: classes.rowAlign },
                        React.createElement(Typography, { className: classes.newExamBoxPrice, fontWeight: "600" },
                            "\u20B9 ",
                            exam.totalExamPrice,
                            " /\u00A0"),
                        React.createElement(Typography, { className: classes.newExamBoxPrice, fontWeight: "600" },
                            "$ ",
                            exam.examPriceUSD)),
                    React.createElement(Button, { size: "small", style: {
                            color: '#6B6B6B',
                            fontSize: '12px',
                            fontWeight: '500 !important',
                            borderRadius: '5px',
                        }, onClick: () => navigate(`/${exam.examID}`) }, "More Details")),
                React.createElement(Button, { variant: "contained", size: "small", style: {
                        backgroundColor: '#4154F1',
                        padding: '0.4rem 1.25rem',
                        borderRadius: '5px',
                    }, onClick: () => {
                        var _a;
                        if (user === null || user === void 0 ? void 0 : user.loggedIn) {
                            navigate('/payment', {
                                state: {
                                    examID: exam.examID,
                                    examName: exam.examName,
                                    studentID: (_a = user.userID) !== null && _a !== void 0 ? _a : user.studentId,
                                    tenantID: exam.tenantID,
                                    amount: exam.totalExamPrice,
                                    amountUSD: exam.examPriceUSD,
                                },
                            });
                        }
                        else {
                            dispatch(loginModalOpen());
                        }
                    } }, "BUY NOW")))));
};
export default NewExamBox;
