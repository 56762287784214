import { Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../UIElements/Icons/Loader';
import { useStyle } from '../../styles/style';
import ImageSlider from '../BaseComponents/ImageSlider';
import ContactUsNew from '../ContactUs/ContactUsNew';
import ExamList from './ExamList';
const NewLandingPage = () => {
    var _a, _b, _c, _d, _e;
    const dispatch = useDispatch();
    const { exams, systemMeta } = useSelector((state) => state.exam);
    const { user } = useSelector((state) => state.auth);
    const { examListLoader: loader } = useSelector((state) => state.exam.loader);
    const SliderImages = (_b = (_a = systemMeta === null || systemMeta === void 0 ? void 0 : systemMeta.filter((meta) => meta.metaName === 'HOME_PAGE_IMAGE')) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.metaDescription;
    const SliderData = (_d = (_c = systemMeta === null || systemMeta === void 0 ? void 0 : systemMeta.filter((meta) => meta.metaName === 'HOME_PAGE_DATA')) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.metaDescription;
    const classes = useStyle();
    return (React.createElement("div", { className: classes.mainBody },
        SliderImages && ((_e = JSON.parse(SliderImages)) === null || _e === void 0 ? void 0 : _e.length) && (React.createElement(ImageSlider, { images: JSON.parse(SliderImages), data: JSON.parse(SliderData) })),
        React.createElement("div", { className: classes.landingMain },
            React.createElement("div", { className: classes.landingExamDetails },
                React.createElement(Typography, { color: "#5E63F2", fontWeight: "600", style: {
                        background: 'rgb(236, 237, 253)',
                        position: 'absolute',
                        left: '50%',
                        padding: '0.5rem 1rem',
                        borderRadius: '5rem',
                        marginBottom: '2rem',
                        transform: 'translate(-50%, 0)',
                    } }, "Exams Curated"),
                React.createElement("div", { className: classes.landingExamDetailMain }, loader ? (React.createElement(Loader, { loaderClass: classes.loader })) : (React.createElement(ExamList, { exams: exams }))))),
        React.createElement(ContactUsNew, null)));
};
export default NewLandingPage;
