import { Box, Modal, Stack } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StudentLoginIcon from '../../UIElements/StudentLoginIcon';
import TeacherLoginIcon from '../../UIElements/TeacherLoginIcon';
import { loginMasterClose, loginModalOpen, postPageAudit, } from '../../store/actions';
import { useStyle } from '../../styles/style';
const LoginMaster = () => {
    const classes = useStyle();
    const dispatch = useDispatch();
    const { loginMaster } = useSelector((state) => state.auth.modals);
    const handleClose = () => {
        dispatch(loginMasterClose());
    };
    return (loginMaster && (React.createElement(Modal, { open: loginMaster, onClose: handleClose, disableScrollLock: true },
        React.createElement(Box, { className: classes.loginModal },
            React.createElement(Stack, { className: classes.loginIconTab },
                React.createElement(StudentLoginIcon, { onClick: () => {
                        dispatch(postPageAudit({
                            page: 'STUDENT PORTAL:LoginModalOpen',
                        }));
                        dispatch(loginModalOpen());
                    } }),
                React.createElement(TeacherLoginIcon, { onClick: () => {
                        dispatch(postPageAudit({
                            page: 'STUDENT PORTAL:TeacherPortalNavigate',
                        }));
                        window.location.replace(`${process.env.REACT_APP_TEACHER_PORTAL_URL}/login` || '');
                    } }))))));
};
export default LoginMaster;
