import { FiberManualRecord, } from '@mui/icons-material';
import { IconButton, Slide } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import HTMLString from 'react-html-string';
import { PRIMARY_COLOR } from '../../styles/colors';
import { useStyle } from '../../styles/style';
const ImageSlider = ({ images, data }) => {
    const timerRef = useRef();
    const [imageIndex, setImageIndex] = useState(0);
    const parentWidth = 500;
    const classes = useStyle();
    const goToPrevious = () => {
        const isFirstSlide = imageIndex === 0;
        const newIndex = isFirstSlide ? images.length - 1 : imageIndex - 1;
        setImageIndex(newIndex);
    };
    const goToNext = useCallback(() => {
        const isLastSlide = imageIndex === images.length - 1;
        const newIndex = isLastSlide ? 0 : imageIndex + 1;
        setImageIndex(newIndex);
    }, [imageIndex, images]);
    const goToSlide = (slideIndex) => {
        setImageIndex(slideIndex);
    };
    useEffect(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            goToNext();
        }, 5000);
        return () => clearTimeout(timerRef.current);
    }, [goToNext]);
    return (React.createElement("div", { className: classes.sliderStyles },
        React.createElement("div", { style: { background: `url(${images[imageIndex].url})` }, className: classes.imageSlider }, data.length > imageIndex && (React.createElement(Slide, { direction: "up", in: data.length > imageIndex, mountOnEnter: true, unmountOnExit: true, timeout: 3000 },
            React.createElement("div", { style: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '-webkit-fill-available',
                    width: '-webkit-fill-available',
                    padding: '5vw 10vw',
                    color: 'white',
                } },
                React.createElement("div", { className: classes.sliderText },
                    React.createElement(HTMLString, { html: data[imageIndex] })))))),
        React.createElement("div", { className: classes.dotsContainerStyles }, images.map((image, slideIndex) => (React.createElement(IconButton, { className: classes.dotStyle, key: slideIndex, onClick: () => goToSlide(slideIndex) },
            React.createElement(FiberManualRecord, { style: {
                    fill: slideIndex === imageIndex ? PRIMARY_COLOR.GREY20 : '#fff',
                }, fontSize: "small" })))))));
};
export default ImageSlider;
