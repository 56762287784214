import React from 'react';
import Login from './Login';
import SignUp from './SignUp';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';
import LoginMaster from './LoginMaster';
import SignUpMaster from './SignUpMaster';
const Modal = () => (React.createElement(React.Fragment, null,
    React.createElement(LoginMaster, null),
    React.createElement(SignUpMaster, null),
    React.createElement(Login, null),
    React.createElement(SignUp, null),
    React.createElement(ResetPassword, null),
    React.createElement(ForgotPassword, null)));
export default Modal;
