import { zodResolver } from '@hookform/resolvers/zod';
import { CallOutlined, LocationOnOutlined, MailOutlined, ScheduleOutlined, } from '@mui/icons-material';
import { Button, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import { postContactUsDetail } from '../../store/actions';
import { useStyle } from '../../styles/style';
const ContactUsNew = () => {
    var _a, _b, _c, _d, _e, _f;
    const classes = useStyle();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const formValidation = z.object({
        userName: z.string().nonempty('UserName is required.'),
        emailId: z.string().nonempty('EmailId is required.'),
        phoneNo: z.string().nonempty('PhoneNo is required.'),
        description: z.string().nonempty('Description is required.'),
    });
    const loginForm = useForm({
        defaultValues: {
            userName: user.firstName ? `${user.firstName} ${user.lastName}` : '',
            emailId: (_a = user.emailId) !== null && _a !== void 0 ? _a : '',
            phoneNo: (_b = user.mobileNumber) !== null && _b !== void 0 ? _b : '',
            description: '',
        },
        resolver: zodResolver(formValidation),
        mode: 'all',
    });
    const handleSubmitClick = (data) => {
        dispatch(postContactUsDetail(Object.assign({}, data)));
    };
    const { register, handleSubmit, control, formState: { errors }, } = loginForm;
    return (React.createElement("div", null,
        React.createElement(Typography, { color: "#5E63F2", fontWeight: "600", style: {
                background: 'rgb(236, 237, 253)',
                position: 'absolute',
                left: '50%',
                padding: '0.5rem 1rem',
                borderRadius: '5rem',
                marginBottom: '2rem',
                transform: 'translate(-50%,0)',
            } }, "Contact Us"),
        React.createElement("div", { className: classes.newcontactUsBody },
            React.createElement("div", { className: classes.newcontactUsLeft },
                React.createElement("div", { className: classes.newContactUsTab },
                    React.createElement(LocationOnOutlined, { className: classes.newContactUsLogo }),
                    React.createElement(Typography, { color: "#0A317B", fontWeight: "600" }, "Address")),
                React.createElement("div", { className: classes.newContactUsTab },
                    React.createElement(CallOutlined, { className: classes.newContactUsLogo }),
                    React.createElement(Typography, { color: "#0A317B", fontWeight: "600" }, "Call Us")),
                React.createElement("div", { className: classes.newContactUsTab },
                    React.createElement(MailOutlined, { className: classes.newContactUsLogo }),
                    React.createElement(Typography, { color: "#0A317B", fontWeight: "600" }, "Email Us")),
                React.createElement("div", { className: classes.newContactUsTab },
                    React.createElement(ScheduleOutlined, { className: classes.newContactUsLogo }),
                    React.createElement(Typography, { color: "#0A317B", fontWeight: "600" }, "Open Hours"))),
            React.createElement(Stack, { direction: "column", alignItems: "center", className: classes.newcontactUsRight },
                React.createElement(Stack, { direction: "row", margin: "0.5rem 0 !important" },
                    React.createElement(TextField, Object.assign({ placeholder: "Your Name", variant: "outlined", size: "small", fullWidth: true, style: { marginRight: '1rem' } }, register('userName'), { error: !!errors.userName, helperText: (_c = errors.userName) === null || _c === void 0 ? void 0 : _c.message })),
                    React.createElement(TextField, Object.assign({ placeholder: "Your Email", variant: "outlined", size: "small", fullWidth: true }, register('emailId'), { error: !!errors.emailId, helperText: (_d = errors.emailId) === null || _d === void 0 ? void 0 : _d.message }))),
                React.createElement(TextField, { placeholder: "Subject", variant: "outlined", size: "small", fullWidth: true }),
                React.createElement(TextField, Object.assign({ placeholder: "Phone No", variant: "outlined", size: "small", fullWidth: true }, register('phoneNo'), { error: !!errors.phoneNo, helperText: (_e = errors.phoneNo) === null || _e === void 0 ? void 0 : _e.message })),
                React.createElement(TextField, Object.assign({ placeholder: "Message", variant: "outlined", size: "small", fullWidth: true, multiline: true, rows: 4 }, register('description'), { error: !!errors.description, helperText: (_f = errors.description) === null || _f === void 0 ? void 0 : _f.message })),
                React.createElement(Button, { variant: "contained", size: "small", fullWidth: false, onClick: handleSubmitClick, style: { width: '8rem', height: '2rem', background: '#4154F1' } }, "Send Message")))));
};
export default ContactUsNew;
