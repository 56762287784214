import clsx from 'clsx';
import React from 'react';
import { useStyle } from '../../styles/style';
const Pagination = (props) => {
    const pageNumbers = [];
    const classes = useStyle();
    for (let i = 1; i <= Math.ceil(props.totalItems / props.itemsPerPage); i++) {
        pageNumbers.push(i);
    }
    return (React.createElement("div", { className: classes.pagination }, pageNumbers.map((number) => (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    React.createElement("div", { key: number, className: props.currentPage === number
            ? clsx(classes.paginationPageItemSelected, classes.paginationPageItem)
            : classes.paginationPageItem, onClick: () => props.setCurrentPage(number) }, number)))));
};
export default Pagination;
