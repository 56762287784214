/* eslint-disable no-eval */
import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useStyle } from '../../styles/style';
import Pagination from '../BaseComponents/Pagination';
import NewExamBox from '../BaseComponents/NewExamBox';
const ExamList = ({ exams }) => {
    var _a, _b, _c;
    const classes = useStyle();
    const [currentPage, setCurrentPage] = useState(1);
    const [examPerPage, setExamPerPage] = useState(8);
    const indexOfLastExam = currentPage * examPerPage;
    const indexOfFirstExam = indexOfLastExam - examPerPage;
    const searchExams = useSelector((state) => state.exam.searchExams);
    const prevExams = searchExams && ((_a = Object.keys(searchExams)) === null || _a === void 0 ? void 0 : _a.length) ? searchExams : exams;
    const currentExams = Object.keys(prevExams)
        .slice(indexOfFirstExam, indexOfLastExam)
        .reduce((result, key) => {
        result[key] = prevExams[key];
        return result;
    }, {});
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    return ((_b = Object.keys(currentExams)) === null || _b === void 0 ? void 0 : _b.length) ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classes.landingExamList }, Object.keys(currentExams).map((examID) => (React.createElement("div", { key: examID, className: classes.landingExamDetail },
            React.createElement(NewExamBox, { exam: currentExams[examID] }))))),
        React.createElement(Pagination, { totalItems: (_c = Object.keys(prevExams)) === null || _c === void 0 ? void 0 : _c.length, itemsPerPage: examPerPage, setCurrentPage: handlePageChange, currentPage: currentPage }))) : (React.createElement(Typography, { variant: "body2" }, "No Exams Found"));
};
export default ExamList;
