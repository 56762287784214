import React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements, } from 'react-router-dom';
import ContactUs from '../components/ContactUs/ContactUs';
import NewLandingPage from '../components/LandingPage/NewLandingPage';
import LearningMainExam from '../components/LearningExam/LearningMainExam';
import MainComponent from '../components/MainComponent';
import MainExam from '../components/MainExam';
import Result from '../components/MainExam/Result';
import MyLearning from '../components/MyLearning/MyLearning';
import ExamLanding from '../components/Pages/ExamLanding';
import ForgotPassword from '../components/Pages/ForgotPassword';
import MyAccount from '../components/Pages/MyAccount';
import Payment from '../components/Pages/Payment';
import PrivacyPolicy from '../components/Pages/PrivacyPolicy';
import ResultAnalysis from '../components/Pages/ResultAnalysis';
import RefundPolicy from '../components/Pages/ReturnPolicy';
import TermsAndCondition from '../components/Pages/TermsAndCondition';
import Redirect from '../components/Redirect';
import * as Actions from '../store/actions';
const RouteProvider = () => {
    const dispatch = useDispatch();
    const router = createBrowserRouter(createRoutesFromElements(React.createElement(React.Fragment, null,
        React.createElement(Route, { path: "/", element: React.createElement(MainComponent, null), errorElement: React.createElement(Navigate, { to: "/" }), loader: () => dispatch(Actions.getStudentTakenExam()) },
            React.createElement(Route, { index: true, element: React.createElement(NewLandingPage, null), loader: () => {
                    dispatch(Actions.getCoursesData());
                    dispatch(Actions.getAllExams());
                    dispatch(Actions.getSystemMeta());
                    return dispatch(Actions.postPageAudit({ page: 'STUDENT PORTAL:landing' }));
                } }),
            React.createElement(Route, { path: "forgotPassword", element: React.createElement(ForgotPassword, null), errorElement: React.createElement(Navigate, { to: "/" }) }),
            React.createElement(Route, { path: "payment", element: React.createElement(Payment, null), errorElement: React.createElement(Navigate, { to: "/" }), loader: () => dispatch(Actions.postPageAudit({ page: 'STUDENT PORTAL:PAYMENT' })) }),
            React.createElement(Route, { path: ":examID", element: React.createElement(Redirect, null) }),
            React.createElement(Route, { path: ":examID/status", element: React.createElement(ExamLanding, null), loader: (param) => dispatch(Actions.postPurchaseStatus(param)) }),
            React.createElement(Route, { path: ":studentExamID/result", element: React.createElement(Result, null), errorElement: React.createElement(Navigate, { to: "/" }), loader: (param) => {
                    dispatch(Actions.postPageAudit({ page: 'STUDENT PORTAL:Result' }));
                    return dispatch(Actions.getStudentExamResult(param));
                } }),
            React.createElement(Route, { path: ":examID/result-analysis", element: React.createElement(ResultAnalysis, null), errorElement: React.createElement(Navigate, { to: "/" }), loader: (param) => dispatch(Actions.getStudentExamResultAnalysis(param)) }),
            React.createElement(Route, { path: "my-learning", element: React.createElement(MyLearning, null), loader: () => {
                    dispatch(Actions.postPageAudit({ page: 'STUDENT PORTAL:MyLearning' }));
                    return dispatch(Actions.getStudentTakenExam());
                } }),
            React.createElement(Route, { path: "my-account", element: React.createElement(MyAccount, null), loader: () => dispatch(Actions.postPageAudit({ page: 'STUDENT PORTAL:MyAccount' })) }),
            React.createElement(Route, { path: "Term-Condition.html", element: React.createElement(TermsAndCondition, null), errorElement: React.createElement(Navigate, { to: "/" }) }),
            React.createElement(Route, { path: "Term-Condition", element: React.createElement(TermsAndCondition, null), errorElement: React.createElement(Navigate, { to: "/" }) }),
            React.createElement(Route, { path: "Privacy-policy.html", element: React.createElement(PrivacyPolicy, null), errorElement: React.createElement(Navigate, { to: "/" }) }),
            React.createElement(Route, { path: "Privacy-policy", element: React.createElement(PrivacyPolicy, null), errorElement: React.createElement(Navigate, { to: "/" }) }),
            React.createElement(Route, { path: "contactUs", element: React.createElement(ContactUs, null), errorElement: React.createElement(Navigate, { to: "/" }), loader: () => dispatch(Actions.postPageAudit({ page: 'STUDENT PORTAL:ContactUs' })) }),
            React.createElement(Route, { path: "refund-policy", element: React.createElement(RefundPolicy, null) }),
            React.createElement(Route, { path: "refund-policy.html", element: React.createElement(RefundPolicy, null) })),
        React.createElement(Route, { path: "exam", element: React.createElement(MainExam, null), errorElement: React.createElement(Navigate, { to: "/" }), loader: () => dispatch(Actions.postPageAudit({ page: 'STUDENT PORTAL:Exam' })) }),
        React.createElement(Route, { path: "learning-exam", element: React.createElement(LearningMainExam, null), errorElement: React.createElement(Navigate, { to: "/" }), loader: () => dispatch(Actions.postPageAudit({ page: 'STUDENT PORTAL:LearningExam' })) }))));
    return React.createElement(RouterProvider, { router: router });
};
export default RouteProvider;
