var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useNestedMenuContext } from './NestedMenuContext';
const useStyles = makeStyles(() => ({
    subMenuItem: {
        display: 'flex',
        alignItems: 'center',
    },
    subMenuItemCaption: {
        flex: 1,
    },
    subMenuItemIcon: {
        justifyContent: 'flex-end',
    },
}), { name: 'NestedMenu' });
const NestedMenu = (props) => {
    const { configuration, onClose, open } = props, others = __rest(props, ["configuration", "onClose", "open"]);
    const { openMenu, closeAllMenus, closeMenu, findOpenMenu } = useNestedMenuContext();
    const [activeItemIndex, setActiveItemIndex] = useState();
    const handleSubMenuClick = (event) => {
        const configKey = event.currentTarget.getAttribute('data-config-key');
        if (configKey) {
            openMenu(configKey, event.currentTarget);
        }
    };
    const handleKeyDown = (event) => {
        event.stopPropagation();
        const length = configuration.length;
        // If no item is selected, and any arrow button gets clicked, then we want to select the first item
        if (['ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft'].includes(event.key) &&
            typeof activeItemIndex === 'undefined') {
            setActiveItemIndex(0);
            return;
        }
        const config = configuration[activeItemIndex];
        if (event.key === 'ArrowDown') {
            if (activeItemIndex + 1 >= length) {
                setActiveItemIndex(0);
                return;
            }
            setActiveItemIndex(activeItemIndex + 1);
        }
        else if (event.key === 'ArrowUp') {
            if (activeItemIndex === 0) {
                setActiveItemIndex(length - 1);
                return;
            }
            setActiveItemIndex(activeItemIndex - 1);
        }
        else if (event.key === 'ArrowRight' && Array.isArray(config.subMenu)) {
            const target = document.querySelector(`*[data-config-key="${config.key}"]`);
            openMenu(config.key, target);
        }
        else if (event.key === 'ArrowLeft') {
            closeMenu(config.key, true);
        }
    };
    return (React.createElement(Menu, Object.assign({}, others, { open: open, onClose: closeAllMenus, onKeyDown: handleKeyDown }), configuration.length &&
        (configuration === null || configuration === void 0 ? void 0 : configuration.map((config, index) => {
            if (!Array.isArray(config.subMenu) || config.subMenu.length === 0) {
                return (React.createElement(MenuItem
                //button
                , { 
                    //button
                    key: config.key, selected: index === activeItemIndex, onClick: (e) => {
                        closeAllMenus({}, 'backdropClick');
                        if (config.onClick) {
                            config.onClick(e);
                        }
                    } }, config.caption));
            }
            const openMenuConfig = findOpenMenu(config.key);
            const isOpen = typeof openMenuConfig === 'object';
            return [
                React.createElement(MenuItem
                //button
                , { 
                    //button
                    key: config.key, selected: index === activeItemIndex, "data-config-key": config.key, onClick: handleSubMenuClick },
                    React.createElement("span", null, config.caption),
                    React.createElement(ListItemIcon, null,
                        React.createElement(ArrowRightIcon, null))),
                React.createElement(NestedMenu, { key: `${config.key}_menu`, configuration: config.subMenu, open: isOpen, anchorEl: isOpen ? openMenuConfig.target : null, anchorOrigin: { vertical: 'center', horizontal: 'right' }, transformOrigin: { vertical: 'top', horizontal: 'left' }, 
                    //getContentAnchorEl={null}
                    onClose: closeAllMenus }),
            ];
        }))));
};
export default NestedMenu;
